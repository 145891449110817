import { Controller } from "@hotwired/stimulus";

export default class Dropdown extends Controller {
  static targets = ["trigger", "content"];

  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    const dropdownTriggeredId = urlParams.get("dropdown_id");

    const dropdown = this.element;
    const trigger = this.triggerTarget;
    const content = this.contentTarget;

    this.#setup_toggling(trigger, content, dropdownTriggeredId, dropdown);
  }

  #setup_toggling(trigger, content, dropdownTriggeredId, dropdown) {
    trigger.addEventListener("click", (evt) => {
      trigger.children[0].classList.toggle("rotate-90");
      dropdown.classList.toggle("is-active");
      content.classList.toggle("tw-hidden");
    });
    if (dropdownTriggeredId) {
      if (trigger.dataset.dropdownId == dropdownTriggeredId) {
        trigger.click();
      }
    }
  }
}
