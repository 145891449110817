import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['label', 'input', 'existingFile'];

  handleRemoveFile(event) {
    event.preventDefault();

    this.inputTarget.value = '';
    this.inputTarget.closest('form').removeAttribute('enctype')
    this.inputTarget.closest('form').requestSubmit();
  }

  handleFiles(files) {
    if(this.existingFileTarget) { this.existingFileTarget.src = window.URL.createObjectURL(files[0]); }
    this.inputTarget.closest('form').requestSubmit();
  }

  handleClick(event) {
    event.preventDefault();
    this.inputTarget.click();
  }

  connect() {
    this.inputTarget.addEventListener('change', (evt) => {
      if(evt.currentTarget.files.length > 0) {
        this.handleFiles(evt.currentTarget.files);

        this.inputTarget.closest("form").requestSubmit();
      }
    })
  }
}
