import { Controller } from "@hotwired/stimulus";
import { calculatorSelector, addEventListenerToSelector, fixedAmount, fixedPercentage } from "../discounts/actions/calculators";
import { ItemButtonsComponent, addListenerToActionButtons,  } from "../discounts/actions/create_item";

export default class DiscountActions extends Controller {
  static targets = ["actionSelector", "addActionBtn", "actionContainer", "items"];

  connect() {
    let items = this.#convertToJSArray(this.itemsTarget.dataset.items);
    let variants = this.#convertToJSArray(this.itemsTarget.dataset.variants);
    this.#actions(items, variants);
  }

  remove(event) {
    event.target.parentElement.remove();
  }

  change_calculator(event) {
    let currency = this.actionSelectorTarget.dataset.currency;
    
    switch (event.target.value) {
      case 'FixedAmount':
        event.target.parentElement.nextElementSibling.replaceWith(fixedAmount(currency));
        break;
      case 'FixedPercentage':
        event.target.parentElement.nextElementSibling.replaceWith(fixedPercentage(currency));
        break;
    }
  }  

  #actions(items, variants) {
    let currency = this.actionSelectorTarget.dataset.currency;

    this.addActionBtnTarget.addEventListener('click', (e)=> {
      e.preventDefault();
      switch (this.actionSelectorTarget.value) {
        case "create_item":
          let container = ItemButtonsComponent();
          if (!this.actionContainerTarget.querySelector('#action-buttons')) {
            this.actionContainerTarget.appendChild(container);
          }
          addListenerToActionButtons(container, this.actionContainerTarget, items, variants);
          break;
        case "order_adjustment":
          if (!this.actionContainerTarget.querySelector('#order_adjustment')) {
            let node = calculatorSelector('Order adjustment', 'order_adjustment', 'OrderAdjustment');
            this.actionContainerTarget.appendChild(node);
            addEventListenerToSelector(node, currency);
          }
          break;
        case "per_item_adjustment":
          if (!this.actionContainerTarget.querySelector('#per_item_adjustment')) {
            let node = calculatorSelector('Per item adjustment', 'per_item_adjustment', 'PerItemAdjustment');
            this.actionContainerTarget.appendChild(node);
            addEventListenerToSelector(node, currency);
          }
          break;
      }
    })
  }

  #convertToJSArray(rubyStyleString) {
    let jsonString = rubyStyleString
      .replace(/:([a-zA-Z_]+)/g, '"$1"')
      .replace(/=>/g, ':')
      .replace(/'/g, '"');

    let jsArray;
    try {
      jsArray = JSON.parse(jsonString);
    } catch (e) {
      console.error("Error parsing JSON string: ", e);
    }
    return jsArray;
  }
}