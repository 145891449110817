function selectorElement(id, objects) {
  let select = `
    <select 
      name="discount[rules][][item_id]" 
      id="${id}" 
      class="tw-p-2 tw-border-primary tw-rounded tw-bg-white tw-border tw-w-full"
    >
      ${
        objects.map((object) => {
          return `<option value="${object.id}">${object.title}</option>`;
        }).join('')
      }
    </select>
  `;
  return select;
}

function addItem(items) {
  let node = document.createElement("div");
  node.className = "tw-w-full tw-mb-4 tw-relative tw-w-full tw-shadow-lg tw-border tw-rounded-xl tw-p-4";
  node.id = "";
  node.innerHTML = `
    <p class="mdi mdi-close-circle tw-text-jalappeno-alert-light tw-absolute tw-top-1 tw-right-2 tw-text-2xl tw-duration-300 hover:tw-scale-110" data-action="click->discount-rule#remove"></p>
    <h3 class="tw-my-2 tw-font-bold tw-text-xl">
      Select item
    </h3>
    <input value=""
      autocomplete="off"
      type="hidden"
      name="discount[rules][][rule_id]"
      id="discount_rules__rule_id"
    >
    <input value="Discounts::Rules::Item" autocomplete="off" type="hidden" name="discount[rules][][rule_type]" id="discount_id">
    <input value="Item" autocomplete="off" type="hidden" name="discount[rules][][item_type]" id="discount_id">

    <div class="tw-w-full">
      <label class="tw-text-gray-800 tw-font-bold" for="discount_rules_item_id">
        Choose item
      </label>
      ${selectorElement('discount_rules__item_id', items)}
    </div>

    <div class="tw-w-full tw-mt-2">
      <label class="tw-text-gray-800 tw-font-bold" for="discount_rules_quantity">
        Quantity
      </label>
      <input type="number" 
        name=discount[rules][][quantity]
        value="10"
        class="tw-outline-none tw-w-full tw-border-outline-none tw-rounded
          tw-border-slate-200 hover:tw-border-slate-300 focus:tw-ring-1 
          focus:tw-ring-indigo-600 tw-p-1 my-1 tw-text-lg tw-border
        "
      >
    </div>
  `
  if (items.length === 0) {
    node.innerHTML = 'You have no items!'
  }
  return node;
}

function addVariant(variants) {
  let node = document.createElement("div");
  node.className = "tw-w-full tw-mb-4 tw-relative tw-w-full tw-shadow-lg tw-border tw-rounded-xl tw-p-4";
  node.id = "";
  node.innerHTML = `
    <p class="mdi mdi-close-circle tw-text-jalappeno-alert-light tw-absolute tw-top-1 tw-right-2 tw-text-2xl tw-duration-300 hover:tw-scale-110" data-action="click->discount-rule#remove"></p>
    <h3 class="tw-my-2 tw-font-bold tw-text-xl">
      Select variant
    </h3>
    <input value=""
      autocomplete="off"
      type="hidden"
      name="discount[rules][][rule_id]"
      id="discount_rules__rule_id"
    >
    <input value="Discounts::Rules::Item" autocomplete="off" type="hidden" name="discount[rules][][rule_type]" id="discount_id">
    <input value="Variant" autocomplete="off" type="hidden" name="discount[rules][][item_type]" id="discount_id">

    <div class="tw-w-full">
      <label class="tw-text-gray-800 tw-font-bold" for="discount_rules_item_id">
        Choose item
      </label>
      ${selectorElement('discount_rules__item_id', variants)}
    </div>

    <div class="tw-w-full tw-mt-2">
      <label class="tw-text-gray-800 tw-font-bold" for="discount_rules_quantity">
        Quantity
      </label>
      <input type="number" 
        name=discount[rules][][quantity]
        value="10"
        class="tw-outline-none tw-w-full tw-border-outline-none tw-rounded
          tw-border-slate-200 hover:tw-border-slate-300 focus:tw-ring-1 
          focus:tw-ring-indigo-600 tw-p-1 my-1 tw-text-lg tw-border
        "
      >
    </div>
  `

  if (variants.length === 0) {
    node.innerHTML = 'You have no variants!'
  }
  return node;
}

export function addListenerToButtons(buttonsContainer, rulesContainer, variants, items) {
  buttonsContainer.querySelectorAll('button').forEach((button) => {
    button.addEventListener('click', (event) => {
      event.preventDefault();
      if (button.id === 'add-item-button') {
        rulesContainer.appendChild(addItem(items));
        return 
      } rulesContainer.appendChild(addVariant(variants));
    })
  });
}

export function ButtonsContainer() {
  let node = document.createElement("div");
  node.className = "tw-col-span-1 md:tw-col-span-2 tw-mb-4";
  node.id = "buttons";
  node.innerHTML = `
    <div class="tw-flex tw-flex-row tw-gap-2 tw-gap-y-1">
      <button name="button" type="disabled" id="add-item-button"
        class="disabled:tw-bg-jalappeno-primary/20 focus:tw-ring-4 focus:tw-bg-jalappeno-primary/60
          hover:tw-bg-jalappeno-primary/90 tw-bg-jalappeno-primary tw-border tw-border-jalappeno-primary
          tw-text-white tw-border-solid hover:tw-shadow tw-inline-flex tw-items-center tw-gap-2 
          tw-cursor-pointer tw-rounded-md tw-transition tw-duration-200 tw-text-center tw-whitespace-nowrap
          tw-py-2 tw-px-4 tw-w-fit tw-py-2 tw-px-4 tw-w-fit
        "
      >
        Add item
      </button>

      <button name="button" type="disabled" id="add-variant-button"
        class="disabled:tw-bg-jalappeno-primary/20 focus:tw-ring-4 focus:tw-bg-jalappeno-primary/60
          hover:tw-bg-jalappeno-primary/90 tw-bg-jalappeno-primary tw-border tw-border-jalappeno-primary
          tw-text-white tw-border-solid hover:tw-shadow tw-inline-flex tw-items-center tw-gap-2 
          tw-cursor-pointer tw-rounded-md tw-transition tw-duration-200 tw-text-center tw-whitespace-nowrap
          tw-py-2 tw-px-4 tw-w-fit tw-py-2 tw-px-4 tw-w-fit
        "
      >
        Add Variant
      </button>
    </div
  `
  return node
}

