import { Controller } from "@hotwired/stimulus";

export default class ShownModalComponent extends Controller {
  static targets = ["modal"];

  hideModal() {
    this.modalTarget.classList.add("tw-hidden");
    this.modalTarget.classList.remove("tw-flex");
    this.element.dataset.state = "inactive";
  }

  showModal() {
    this.modalTarget.classList.remove("tw-hidden");
    this.modalTarget.classList.add("tw-flex");
    this.element.setAttribute("data-state", "active");
  }

  connect() {
    // this.hideModal();
    tail.select(".multiselect", {
      search: true,
      descriptions: true,
      hideSelected: true,
      hideDisabled: true,
      multiLimit: 100,
      multiShowCount: false,
      multiContainer: true,
    });
    const clickTargetName = this.modalTarget.dataset.name;
    const clickTargets = document.querySelectorAll(`#${clickTargetName}`);

    const _self = this;

    if (clickTargets) {
      clickTargets.forEach((target) => {
        target.addEventListener("click", () => {
          _self.showModal();
          tail.select(".multiselect", {
            search: true,
            descriptions: true,
            hideSelected: true,
            hideDisabled: true,
            multiLimit: 100,
            multiShowCount: false,
            multiContainer: true,
          });
        });
      });
    }

    this.modalTarget.addEventListener("click", (event) => {
      if (this.modalTarget === event.target) {
        this.hideModal();
      }
    });

    window.dispatchEvent(new CustomEvent("modalInitializationComplete"));
  }
}
