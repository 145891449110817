export function orderTotalRuleForm(currency) {
  let node = document.createElement("div");
  node.className = "tw-w-full rule tw-mb-4 tw-relative tw-w-full tw-shadow-lg tw-border tw-rounded-xl tw-p-4";
  node.id = "order_total";
  node.innerHTML = `
    <p class="mdi mdi-close-circle tw-text-jalappeno-alert-light tw-absolute tw-top-1 tw-right-2 tw-text-2xl tw-duration-300 hover:tw-scale-110" data-action="click->discount-rule#remove"></p>
    <h3 class="tw-my-2 tw-font-bold tw-text-2xl">
      Order total rule
    </h3>
    <input value=""
      autocomplete="off"
      type="hidden"
      name="discount[rules][][rule_id]"
      id="discount_rules__rule_id"
    >
    <input value="Discounts::Rules::OrderTotal"
      autocomplete="off"
      type="hidden"
      name="discount[rules][][rule_type]"
      id="discount_rules__rule_type"
    >
    <div class="tw-w-full">
      <label class="tw-text-gray-800 tw-font-bold" for="discount_rules_greater_than_cents">
        Minimum order total (cents: ${currency})
      </label> 
      <input type="number" id="discount_rules__greater_than_cents"
        name=discount[rules][][greater_than_cents]
        value="100"
        class="tw-outline-none tw-w-full tw-border-outline-none tw-rounded
          tw-border-slate-200 hover:tw-border-slate-300 focus:tw-ring-1 
          focus:tw-ring-indigo-600 tw-p-1 my-1 tw-text-lg tw-border
        "
      >
    </div>

    <div class="tw-w-full">
      <label class="tw-text-gray-800 tw-font-bold" for="discount_rules_less_than_cents">
        Maximum order total (cents: ${currency})
      </label> 
      <input 
        type="number"
        name=discount[rules][][less_than_cents]
        id="discount_rules__less_than_cents"
        value="1000"
        class="tw-outline-none tw-w-full tw-border-outline-none tw-rounded
          tw-border-slate-200 hover:tw-border-slate-300 focus:tw-ring-1 
          focus:tw-ring-indigo-600 tw-p-1 my-1 tw-text-lg tw-border
        "
      >
    </div>
  `;
  return node;
}
