export function calculatorSelector(name, id, actionType) {
  let node = document.createElement("div");
  node.className = "tw-col-span-1 md:tw-col-span-2 tw-flex tw-flex-col tw-gap-y-1 tw-w-full tw-mb-4 tw-relative tw-w-full tw-shadow-lg tw-border tw-rounded-xl tw-p-4";
  node.id = id;
  node.innerHTML = `
    <h3 class="tw-my-2 tw-font-bold tw-text-xl">
      ${name}
    </h3>
    <input value=""
      autocomplete="off"
      type="hidden"
      name="discount[actions][][action_id]"
      id="discount_actions__action_id"
    >
    <input value=${actionType} autocomplete="off" type="hidden" name="discount[actions][][action_type]" id="discount_id">
    <p class="mdi mdi-close-circle tw-text-jalappeno-alert-light tw-absolute tw-top-1 tw-right-2 tw-text-2xl tw-duration-300 hover:tw-scale-110" data-action="click->discount-action#remove"></p>
    <div class="tw-w-full tw-mt-2 tw-flex tw-flex-col tw-gap-y-1">
      <label class="tw-text-gray-800 tw-font-bold" for="discount_action_type">
        Calculator
      </label>
      
      <select 
        name=discount[actions][][calculator_type]
        id="calculator_type"
        class="tw-p-2 tw-border-primary tw-rounded tw-bg-white tw-border"
      >
        <option value="FixedPercentage">Fixed percentage</option>
        <option value="FixedAmount">Fixed amount</option>
      </select>
    </div>

    <div class="tw-w-full tw-mt-2" id="calculator-fields">
      <label class="tw-text-gray-800 tw-font-bold" for="discount_actions_percentage">
        Percentage
      </label>
      <input type="number" 
        name=discount[actions][][calculator_params][percentage]
        value="0"
        max="100"
        class="tw-outline-none tw-w-full tw-border-outline-none tw-rounded
          tw-border-slate-200 hover:tw-border-slate-300 focus:tw-ring-1 
          focus:tw-ring-indigo-600 tw-p-1 my-1 tw-text-lg tw-border
        "
      >
    </div>
  `;
  return node
}

export function fixedPercentage() {
  let node = document.createElement("div");
  node.className = "tw-w-full tw-mt-2";
  node.id = "";
  node.innerHTML = `
    <label class="tw-text-gray-800 tw-font-bold" for="discount_actions_percentage">
      Percentage
    </label>
    <input type="number" 
      name=discount[actions][][calculator_params][percentage]
      value="0"
      max="100"
      class="tw-outline-none tw-w-full tw-border-outline-none tw-rounded
        tw-border-slate-200 hover:tw-border-slate-300 focus:tw-ring-1 
        focus:tw-ring-indigo-600 tw-p-1 my-1 tw-text-lg tw-border
      "
    >
  `;

  return node
}

export function fixedAmount(currency) {
  let node = document.createElement("div");
  node.className = "tw-w-full tw-mt-2";
  node.id = "";
  node.innerHTML = `
    <label class="tw-text-gray-800 tw-font-bold" for="discount_actions_amount_cents">
      Amount cents (${currency})
    </label>
    <input type="number" 
      name=discount[actions][][calculator_params][amount_cents]
      value="100"
      class="tw-outline-none tw-w-full tw-border-outline-none tw-rounded
        tw-border-slate-200 hover:tw-border-slate-300 focus:tw-ring-1 
        focus:tw-ring-indigo-600 tw-p-1 my-1 tw-text-lg tw-border
      "
    >
  `;

  return node
}

export function addEventListenerToSelector(node, currency) {
  
  let selectorContainer = node.querySelector('#calculator-fields');
  let selector = node.querySelector('select#calculator_type');
  selector.addEventListener('change', (event) => {
    switch (selector.value) {
      case 'FixedPercentage':
        selectorContainer.replaceChildren(fixedPercentage())
        break;
      case 'FixedAmount':
        selectorContainer.replaceChildren(fixedAmount(currency))
        break;
    }    
  });
}