import { Controller } from "@hotwired/stimulus";
import { orderTotalRuleForm } from "../discounts/rules";
import { 
  ButtonsContainer,
  addListenerToButtons,
  categoryButtonContainer,
  addListenerToCategoryButton
} from "../discounts/rules";

export default class DiscountRules extends Controller {
  static targets = ["ruleSelector", "addRuleBtn", "actionSelector", "rulesContainer", "items"];

  connect() {
    let items = this.#convertToJSArray(this.itemsTarget.dataset.items)
    let variants = this.#convertToJSArray(this.itemsTarget.dataset.variants)
    let categories = this.#convertToJSArray(this.itemsTarget.dataset.categories)
    this.#rules(items, variants, categories)
  }

  remove(event) {
    event.target.parentElement.remove()
  }

  orderTotalRule(event) {
    orderTotalRuleForm();
  }

  #rules(items, variants, categories) {
    let currency = this.ruleSelectorTarget.dataset.currency;
    this.addRuleBtnTarget.addEventListener('click', (e)=> {
      e.preventDefault();
      switch (this.ruleSelectorTarget.value) {
        case "order_total":
          if (!this.rulesContainerTarget.querySelector('#order_total') ) {
            this.rulesContainerTarget.appendChild(orderTotalRuleForm(currency));
          }
          break;
        case "specific_item":
          let container = ButtonsContainer();
          if (!this.rulesContainerTarget.querySelector('#buttons') ) {
            this.rulesContainerTarget.appendChild(container);
          }
          addListenerToButtons(container, this.rulesContainerTarget, variants, items);
          break;
        case "category":
          let button = categoryButtonContainer();
          if (!this.rulesContainerTarget.querySelector('#category-button') ) {
            this.rulesContainerTarget.appendChild(button);
          }
          addListenerToCategoryButton(button, this.rulesContainerTarget, categories);
          break;
      }
    })
  }

  #convertToJSArray(rubyStyleString) {
    let jsonString = rubyStyleString
      .replace(/:([a-zA-Z_]+)/g, '"$1"')
      .replace(/=>/g, ':')
      .replace(/'/g, '"');

    let jsArray;
    try {
      jsArray = JSON.parse(jsonString);
    } catch (e) {
      console.error("Error parsing JSON string: ", e);
    }
    return jsArray;
  }
}
